import { getAuthHeader } from "@src/app/superagent";
import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import { FirebaseAuthentication } from "@src/lib/firebase";
import { Agent, User } from "@src/lib/interface";
import request from "superagent";

export const getAgentByPhone = async (phone: string): Promise<Agent> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/byPhone`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .query({ phone })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const getUserByEmail = async (): Promise<User> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/user/getByEmail`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const updateAgentLoggedInTime = async (
  firebaseAuth: FirebaseAuthentication
): Promise<Agent> => {
  const token = (await firebaseAuth.currentUser?.getIdToken()) as string;
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/loggedIn`)
    .set("Authorization", token)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export async function updateAgentData(data: Record<string, any>) {
  return await request
    .put(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/put`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const fetchEnvVariables = async () => {
  try {
    const { body } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/envVariables`)
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .set(await getAuthHeader());

    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

interface AgentProfileResp {
  error: string;
  message: string;
  data?: {
    firebaseToken?: string;
  };
}

export const validateAgentOTP = async (phone: string, code: string): Promise<AgentProfileResp> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/validateOTP`)
    .send({ phone, otp: code })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

interface ValidateOTPPayload {
  phone: string;
  code: string;
  isSignup: boolean;
}

export const validateAgentOTPv2 = async ({
  phone,
  code,
  isSignup,
}: ValidateOTPPayload): Promise<AgentProfileResp> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/v2/validateOTP`)
    .send({ phone, otp: code, isSignup })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const requestAgentOTP = async (
  phone: string,
  isSignup: boolean
): Promise<AgentProfileResp> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/v3/requestOTP`)
    .send({ phone, isSignup })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};
export const getReferralRate = async (): Promise<string> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/referralBonusAmount`)
    .set(await getAuthHeader())
    .then(({ body }) => body?.referralRate)
    .catch(logApiFailureEvent);
};

interface AdjustFeatureFlagValidationResponse {
  success: boolean;
  triggerAdjustEvent: boolean;
}

export const triggerAdjustEventTracking =
  async (): Promise<AdjustFeatureFlagValidationResponse> => {
    try {
      const { body } = await request
        .get(
          `${environmentConfig.REACT_APP_BASE_API_URL}/agentprofile/validateAdjustEventFeatureFlag`
        )
        .retry(1, (err) => {
          if (!err) {
            return false;
          }
          return true;
        });
      return body;
    } catch (error) {
      logApiFailureEvent(error);
      throw error;
    }
  };
