import { Preferences } from "@capacitor/preferences";
import { CbhSegment } from "@clipboard-health/cbh-segment";
import { isPlatform } from "@ionic/react";
import { environmentConfig, isTestNodeEnvironment } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, getAppInfo } from "@src/appV2/lib";
import { logError } from "@src/appV2/lib/analytics";
import { UXCamSdk } from "@src/appV2/lib/UXCamSdk/UXCamSdk";
import { type Worker } from "@src/appV2/Worker/api/types";
import { createSegmentInstance, identifyUser } from "@src/lib/analytics";
import { ZendeskSDK } from "capacitor-zendesk-sdk";
import { enqueueSnackbar } from "notistack";
import { Dispatch } from "redux";

import { fetchEnvVariables, updateAgentData } from "./api";
import { ActionType, LocalStorage } from "./session.model";
import { getZendeskJWTMessagingSDK } from "../../api/zendesk";
import { clearDatadogRumUser, identifyDatadogRumUser } from "../../utils/datadog";
import { ActionType as SpecialityAction } from "../specialities/model";

async function initAnalytics(): Promise<void> {
  createSegmentInstance({
    key: environmentConfig.REACT_APP_SEGMENT_KEY,
    androidKey: environmentConfig.REACT_APP_SEGMENT_ANDROID_KEY,
    iosKey: environmentConfig.REACT_APP_SEGMENT_IOS_KEY,
    webKey: environmentConfig.REACT_APP_SEGMENT_WEB_KEY,
  });

  UXCamSdk.optIntoSchematicRecordings(); //To enable session video recording on iOS

  const configuration = {
    userAppKey: environmentConfig.REACT_APP_UX_CAM_KEY,
    enableAutomaticScreenNameTagging: true,
    enableImprovedScreenCapture: true,
  };
  UXCamSdk.startWithConfiguration(configuration);
}

const IS_TEST_ENV = isTestNodeEnvironment();
if (!IS_TEST_ENV) {
  initAnalytics();
}

export const loadCustomFlags = (dispatch: Dispatch): void => {
  const isSignupFlag = localStorage.getItem("isSignup");
  if (isSignupFlag) {
    dispatch({
      type: ActionType.SET_IS_SIGNUP,
    });
  }
};

export const setEnvVariables = async (dispatch: Dispatch): Promise<void> => {
  const data = await fetchEnvVariables();
  dispatch({
    type: ActionType.SET_ENV_VARIABLES,
    data,
  });
};

export const saveAgentStageandMSAInfo = (
  worker?: Pick<Worker, "stage" | "address" | "userId">
): void => {
  localStorage.setItem("agentstage", worker?.stage ?? "");
  localStorage.setItem("agentmsa", worker?.address?.metropolitanStatisticalArea ?? "");
  localStorage.setItem("workerUserId", worker?.userId ?? "");
};

export const onLoggedOut = (dispatch: Dispatch): void => {
  dispatch({
    type: ActionType.LOGGED_OUT,
  });
  localStorage.removeItem(LocalStorage.RECOGNIZED_HOLIDAYS);
  localStorage.removeItem("claimCheck");
  localStorage.removeItem("isSignup");
  Preferences.clear();
  UXCamSdk.stopSessionAndUploadData();
  clearDatadogRumUser();
};

export const identifyAgent = async (
  dispatch: Dispatch,
  worker: Worker
): Promise<boolean | undefined> => {
  if (!worker) {
    return;
  }

  const { otaBuildId, version } = await getAppInfo();

  if (worker.isFirstSession) {
    if (worker.isIdentifiedInSegment !== true) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let cbhSegment: any = "";
      cbhSegment = new CbhSegment(environmentConfig.REACT_APP_SEGMENT_KEY as string);

      cbhSegment.identify({
        userId: worker.userId.toString(),
        traits: {
          createdAt: worker.createdAt,
          email: worker.email,
          name: worker.name,
          phone: worker.phone,
          type: "Agent",
          version,
          otaBuildId,
        },
      });
    }

    await updateAgentData({
      agentId: worker.userId,
      isFirstSession: false,
      isIdentifiedInSegment: true,
    });
  }

  UXCamSdk.setUserIdentity(worker.name ?? worker.userId ?? "ANONYMOUS");

  UXCamSdk.setUserProperties({
    user_id: worker.userId,
    alias: worker.name,
    email: worker.email,
    qualification: worker.preference?.qualification,
    otaBuildId,
  });

  identifyDatadogRumUser(worker);
  dispatch({
    type: SpecialityAction.GET_SPECIALITIES_DATA,
    data: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TS2339
      experienceDetails: worker?.specialities?.experienceDetails ?? {},
      hasSedationExperience: worker?.specialities?.hasSedationExperience ?? false,
      hasTrayAssemblyExperience: worker?.specialities?.hasTrayAssemblyExperience ?? false,
      experience: worker?.specialities?.experience ?? [],
    },
  });
  identifyUser(worker);
  return true;
};

export const showAgentProfileFailedToLoadError = (dispatch: Dispatch) => {
  enqueueSnackbar({
    message: "Error occurred while fetching worker details",
    color: "error",
  });
  dispatch({
    type: ActionType.SHOW_FETCH_WORKER_FAILURE_ALERT,
    data: {
      isFetchAgentProfileFailureAlertEnabled: true,
    },
  });
};

export const loginZendesk = async (): Promise<void> => {
  if (!isPlatform("capacitor")) {
    return;
  }

  try {
    const token = await getZendeskJWTMessagingSDK();
    await ZendeskSDK.loginUser({ token });
  } catch (error) {
    logError(APP_V2_APP_EVENTS.ZENDESK_LOGIN_ERROR, { error });
  }
};
